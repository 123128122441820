<template>
  <div class="page-wrap">
    <div class="use-pic">
      <img src="../../assets/imgs/jump-pic.png"/>
    </div>
    <div class="wait">正在切换版本，请稍等 . . .</div>
  </div>
</template>

<script>
import {loginJump , getLoginInfo} from '../../api/interFace'
import {setCookies,removeCookies} from '@/utils/helper'
export default {
  name: "jump",
  data(){
    return{
      pageInfo: {},
      store_id: 0,
    }
  },
  created() {
    console.log('2')    
    localStorage.removeItem(document.domain+'_companyInfo');
    if ( this.$route.query.store_id != undefined) {
      this.store_id = this.$route.query.store_id
      localStorage.setItem('storeId',this.$route.query.store_id)      
      // localStorage.setItem('storeId',this.$route.query.store_id)
    }else {
      this.store_id = 0
      localStorage.setItem("InitializeUrl",'')
    }   
    if(this.$route.query.type != undefined){
      localStorage.removeItem('storeId')
    }
    console.log('----这是V2版本jump-----')
    this.getInfo()
    let token = this.$route.query.token
    setCookies('token',token)
    setTimeout(()=>{
      loginJump().then(res=>{
        let data = res.data
        if (data.userinfo.status == 0) {
          let expires = this.getExpiresTime(data.expires_time)
          // 登陆信息
          setCookies('token', data.token, expires)
          setCookies('expires_time', data.expires_time, expires)
          setCookies('uuid', data.userinfo.id, expires)
          setCookies('version', data.version_status)
          this.$router.replace({ path: '/index' })
          this.$store.commit('setMenus', data.menus)
          this.$store.commit('setAuth', data.unique_auth)
          this.$store.commit('setUserInfo', data.userinfo)
          this.$store.commit('setVersion', data.version_status)
          this.$store.commit('setIdentity', data.identity) //唐+ 身份识别
        } else {
          removeCookies('token')
          removeCookies('expires_time')
          this.$Message.error('您已被限制登录')
        }
        
      }).catch(res => {
        console.log(res)
        let data = res === undefined ? {} : res
        this.$Message.error(data.msg || '登录失败')
      })
    },500)
  },  mounted() {
  },
  methods:{
    getInfo(){ 
      let a = localStorage.getItem('storeId') 
      if(!a && typeof(a)!="undefined" && a!=0 ){
         console.log('yyyynull')
      }else{
        this.store_id = localStorage.getItem('storeId')      
      }       

      getLoginInfo({ store_randomnum: this.store_id }).then(res => {
        this.pageInfo = res.data        
        console.log('this.pageInfo',this.pageInfo)
        window.document.title = this.pageInfo.site_title || '速速云签'
        if(this.store_id != 0){
          this.setPageInfo()
        }
      }).catch(err =>{
          console.log('err信息',err)
          localStorage.removeItem(document.domain+'_companyInfo');
        }        
      );
    },
    setPageInfo(){
      this.pageInfo.site_title = this.pageInfo.site_title || '速速云签'      
      localStorage.setItem(document.domain+'_companyInfo', JSON.stringify(this.pageInfo))
    },
    getExpiresTime (expiresTime) {
      let nowTimeNum = Math.round(new Date() / 1000)
      let expiresTimeNum = expiresTime - nowTimeNum
      return parseFloat(parseFloat(parseFloat(expiresTimeNum / 60) / 60) / 24)
    },
  }
}
</script>

<style scoped>
.page-wrap {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background: #EDF0F9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.use-pic {
  width: 584px;
  height: 341px;
}
.use-pic img {
  width: 100%;
  height: 100%;
}
.wait {
  width: 351px;
  height: 30px;
  font-size: 30px;
  color: #545F78;
  margin-top: 20px;
}

</style>
